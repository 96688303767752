import request from '@/utils/request'

//-------------黑名单管理---------
//黑名单列表
export function blackList(data) {
  return request({
    url: '/MA_BlackUser/FindBlackUserPage',
    method: 'post',
    params: data
  })
}

//新增黑名单
export function addBlack(data) {
  return request({
    url: '/MA_BlackUser/BlackUserAdd',
    method: 'post',
    data: data
  })
}

//从黑名单移除
export function outBlack(data) {
  return request({
    url: '/MA_BlackUser/OutBlackUser',
    method: 'post',
    data: data
  })
}

//黑名单流水
export function getBlackFlows(data) {
  return request({
    url: '/MA_BlackUser/BlackListPage',
    method: 'post',
    params: data
  })
}
// 上传黑名单
export function addBlackUsers(form, data) {
  return request({
    url: '/MA_BlackUser/importBlackUsers',
    method: 'post',
    params: data,
		data: form
  })
}
