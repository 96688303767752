<template>
  <div class="wrapper">
    <div class="searchbox">
      <el-form :inline="true">
        <el-form-item label="客户手机号">
          <el-input v-model="phone" clearable placeholder="请输入客户手机号"></el-input>
        </el-form-item>
        <el-form-item label="所属社区">
          <el-select v-model="community" clearable collapse-tags multiple placeholder="请选择">
            <el-option
              v-for="item in communitys"
              :key="item.levelcode"
              :label="item.name"
              :value="item.levelcode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button type="primary" @click="addBlackFn">新增黑名单</el-button>
          <el-button type="primary" @click="addMoreBlackFn('more')">新增多个黑名单</el-button>
          <!-- <el-button type="primary" @click="downloadXles">导入黑名单模板下载</el-button> -->
          <a href="/blackusers.xlsx" target="_blank" style="margin-left: 10px">
						<el-button type="primary" icon="el-icon-download">导入黑名单模板下载</el-button>
					</a>
        </el-form-item>
      </el-form>
    </div>

    <div class="showdatanumber">共查询数据<span>{{ fenye.totalnum }}</span>条</div>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column prop="name" label="客户姓名" align="center">
      </el-table-column>
      <el-table-column prop="nickname" label="客户昵称" align="center">
      </el-table-column>
      <el-table-column prop="type" label="黑名单类型" align="center" :formatter="typeFormat">
      </el-table-column>
      <el-table-column prop="actname" label="活动名称" align="center">
      </el-table-column>
      <el-table-column prop="levelname" label="所属社区" align="center">
      </el-table-column>
      <el-table-column prop="phone" label="手机号" align="center">
      </el-table-column>
      <el-table-column prop="desc" label="拉黑原因" align="center">
      </el-table-column>
      <el-table-column prop="createtime" label="加入黑名单时间" align="center">
      </el-table-column>
      <el-table-column prop="createman" label="操作者" align="center">
      </el-table-column>
      <el-table-column prop="logintime" label="最后登录时间" align="center">
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="350" align="center">
        <template slot-scope="scope">
          <el-button @click="guestdetail(scope.row)" type="primary">客户详情</el-button>
          <el-button @click="deleteBlackFn(scope.row.id)" type="danger">从黑名单移除</el-button>
          <el-button @click="getFlows(scope.row.id)" type="default">原因流水</el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="paginationbox">
			<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="fenye.pagesizes" :page-size="fenye.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="fenye.totalnum">
			</el-pagination>
		</div>


    <!-- 新增黑名单弹框 -->
    <el-dialog title="新增黑名单" :visible.sync="dialogFormVisible" width="600px" :before-close="onBeforeClose" :close-on-click-modal="false">
      <el-form :model="form" :rules="rules" ref="form" class="formbox" label-width="130px">
        <el-form-item label="黑名单用户信息" prop="file" v-if="isMoreBlack == 'more'">
          <el-upload ref="upload" class="upload-demo" action="#" :limit="1" accept=".xlsx"
            :on-change="handleChange" :on-remove="handleRemove" :auto-upload="false">
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="客户编号" prop="accountuuid" v-if="isMoreBlack!='more'">
          <el-input v-model="form.accountuuid" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="加入黑名单描述" prop="desc" v-if="isMoreBlack!='more'">
          <el-input v-model="form.desc" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="黑名单类型" prop="type">
          <el-radio-group v-model="form.type" @change="changeType">
            <el-radio label="0">全局</el-radio>
            <el-radio label="1">普通活动</el-radio>
            <el-radio label="2">社员活动</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item v-if="form.type == 1 || form.type == 2" label="活动名称" prop="actId">
          <el-select v-model="form.actId" filterable placeholder="请选择活动名称" style="width: 100%;">
            <el-option
              v-for="item in actList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm('form')">确 定</el-button>
        <el-button @click="resetForm('form')">取 消</el-button>
      </div>
    </el-dialog>

      <!-- 黑名单原因流水 -->
    <el-dialog title="原因流水" :visible.sync="dialogTableVisible" width="660px"  :close-on-click-modal="false">
      <el-table :data="flowData">
        <el-table-column property="desc" label="原因描述"></el-table-column>
        <el-table-column property="createtime" label="发生时间" width="160"></el-table-column>
      </el-table>

      <div class="paginationbox">
        <el-pagination @size-change="flowSizeChange" @current-change="flowCurrentChange" :current-page="flowCurrentPage" :page-sizes="flowFenye.pagesizes" :page-size="flowFenye.pagesize" layout="total, sizes, prev, pager, next, jumper" :total="flowFenye.totalnum">
        </el-pagination>
      </div>
    </el-dialog>

    <el-dialog title="客户信息" :close-on-click-modal="false" width="860px" :visible.sync="formDialog" v-if='formDialog'>
      <AddForm :Mail='accountUuid'></AddForm>
		</el-dialog>

  </div>
</template>

<script>
import { upLoadFileObj } from '@/api/itemCategory'
import { getDepartmentsByName, addDepartment, updateDepartment, deleteDepartment, communityOfUser } from '@/api/system';
import { blackList, addBlack, outBlack, getBlackFlows, addBlackUsers } from '@/api/blacklist'
import { activityQueryPage } from '@/api/acitvity/index.js'
import { queryPageAct } from '@/api/showLove'
import AddForm from '@/components/kehuDetail'
export default {
  name:"",
  components:{
    AddForm
  },
  props:{},
  data(){
    return {
      tableData:[],
      departmentname:'',
      currentPage: 1,
      fenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10
      },
      operationType: 'add',
      form: {
        file: '',
        type: '0',
        actId: ''
      },
      rules:{
        accountuuid: [
          { required: true, message: '请输入客户编号', trigger: 'blur' }
        ],
        desc: [
          { required: true, message: '请输入加入黑名单描述', trigger: 'blur' }
        ]
      },
      dialogFormVisible: false,
      communitys: [],
      community: [],
      phone:'',
      dialogTableVisible: false,
      flowData: [],
      flowCurrentPage: 1,
      flowFenye: {
        totalnum: 0,
        pagesizes: [10, 20, 30, 40, 50],
        pagesize: 10
      },
      formDialog:false,
      accountUuid:'',
      actList: [],
      normalActList: [],
      volActList: [],
      isMoreBlack: '',
    }
  },
  watch:{},
  created(){},
  mounted(){
    this.getCommunityOfUser();
    this.getNormalActList();
    this.getVolActList();
  },
  computed:{},
  methods:{
    handleChange(file, fileList) {
      // this.fileList = fileList.slice(-3);
      console.log(fileList);
      // let formData = new FormData();
      // formData.append('file', file.raw);
      // upLoadFileObj(formData, {
      //     userid: this.GLOBAL.adminId()
      // }).then((res)=>{
      //   if(res.result==200){
      //     this.$message.success('上传成功')
      //     let url = res.detail[0].path;
      //     this.form.file = url;
      //   }else{
      //     this.$message.error('上传失败')
      //   }
      // })
      this.form.file = fileList[0].raw;
    },
    handleRemove(file, fileList) {
      console.log(fileList);
      this.form.file = "";
    },
    //获取当前登录用户的管辖社区
    getCommunityOfUser(){
      communityOfUser({
        userid: this.GLOBAL.adminId()
      }).then(res=>{
        if(res.result == 200){
          this.communitys =  res.detail;
          this.getList();
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //获取列表
    getList() {
      let arealevelArr = []
      if(this.community.length == 0){
        this.communitys.map(item=>{
          arealevelArr.push(item.levelcode)
        })
      }else{
        arealevelArr = [...this.community];
      }
      blackList({
          userid: this.GLOBAL.adminId(),
          pageSize: this.fenye.pagesize,
          pageNum: this.currentPage,
          phone: this.phone,
          arealevel: arealevelArr.join(',')
      }).then(res=>{
        if(res.result == 200){
          this.fenye.totalnum = res.detail.totalRow;
          this.tableData = res.detail.list;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    changeType(val) {
      if(val == 1) {
        this.actList = this.normalActList;
      } else if(val == 2) {
        this.actList = this.volActList;
      }
      this.form.actId = '';
    },
    getNormalActList() {
      // 普通活动
      activityQueryPage({
        userid: this.GLOBAL.adminId(),
        pageSize: 1000,
        pageNum: 1
      }).then(res => {
        if(res.result == 200){
          res.detail.list.map(item => {
            item.label = item.actname;
            item.value = item.actuuid;
          })
          this.normalActList = res.detail.list;
        }
      })
    },
    getVolActList() {
      queryPageAct({
        userid: this.GLOBAL.adminId(),
        pageSize: 1000,
        pageNum: 1
      }).then( res => {
        if(res.result == 200){
          console.log("volActList:", res.detail);
          res.detail.list.map(item => {
            item.label = item.name;
            item.value = item.uuid;
          })
          this.volActList = res.detail.list;
        }
      })
    },
    typeFormat(row) {
      if(row.type == 0) {
        return '全局'
      } else if(row.type == 1) {
        return '普通活动'
      } else if(row.type == 2) {
        return '社员活动'
      }
    },
    //搜索
    onSearch(){
      this.currentPage = 1;
      this.getList();
    },
    //打开添加白名单表单
    addBlackFn(){
      this.isMoreBlack = '';
      this.handleRemove();
      this.dialogFormVisible = true;
    },
    addMoreBlackFn(type) {
      this.isMoreBlack = type;
      this.dialogFormVisible = true;
    },
    //提交新增白名单
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.isMoreBlack == 'more') {
            //多个黑名单
            let formData = new FormData();
            formData.append('file', this.form.file);
            addBlackUsers(formData, {
              userid: this.GLOBAL.adminId(),
              type: this.form.type,
              actid: this.form.actId
            }).then(res=>{
              if(res.result == 200){
                let detail = res.detail;
                this.$confirm(detail, '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                }).then(() => {
                  this.resetForm('form');
                  this.onSearch();
                }).catch(() => {
                  this.resetForm('form');
                  this.onSearch();
                })
              } else {
                this.$message.error(res.description);
              }
            })
          } else {
            //单个黑名单
            addBlack({
              userid: this.GLOBAL.adminId(),
              accountuuid: this.form.accountuuid,
              desc: this.form.desc,
              type: this.form.type,
              actid: this.form.actId
            }).then(res=>{
              if(res.result == 200){
                this.$message.success('新增黑名单成功')
                this.resetForm('form');
                this.onSearch();
              } else {
                this.$message.error(res.description);
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    //重置新增白名单表单
    resetForm(formName) {
      this.dialogFormVisible = false;
      this.form.file = "";
      this.isMoreBlack = '';
      this.$refs[formName].resetFields();
    },
    //从黑名单移除
    deleteBlackFn(id){
        this.$prompt('请输入移除黑名单描述', '提示', {
          confirmButtonText: '确定移除',
          cancelButtonText: '取消',
          inputPattern: /\S/,
          inputErrorMessage: '请输入移除黑名单的理由'
        }).then(({ value }) => {
          outBlack({
            userid: this.GLOBAL.adminId(),
            id: id,
            desc: value
          }).then(res=>{
            if(res.result == 200){
              this.$message.success('已从黑名单中移除')
              this.onSearch();
            } else {
              this.$message.error(res.description);
            }
          })
        }).catch(() => {
              
        });
    },
    onBeforeClose(done){
      done();
      this.resetForm();
    },
    //切换页面条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.fenye.pagesize = val;
      this.currentPage = 1;
      this.getList();
    },
    //切换分页
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getList();
    },
    
    //删除信息
    deleteUser(id){
      this.$confirm('是否确认删除该数据?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					cancelButtonClass: "btn-custom-cancel",
					type: 'warning'
				}).then(() => {
          deleteDepartment({
              userid: this.GLOBAL.adminId(),
              rolecode: id
          }).then(res=>{
            if(res.result == 200){
              this.$message({
								type: 'success',
								message: '删除成功!'
							});
							this.currentPage = 1;
							this.getList();
            } else {
              this.$message.error(res.description);
            }
          })
				})
      
    },
    //获取原因流水
    getFlows(id){
      if(id){
        this.currentBlackId = id;
      }
      this.dialogTableVisible = true;
      getBlackFlows({
        userid: this.GLOBAL.adminId(),
        id: this.currentBlackId,
        pageSize: this.flowFenye.pagesize,
        pageNum: this.flowCurrentPage
      }).then(res=>{
        if(res.result == 200){
          this.flowData = res.detail.list;
          this.flowFenye.totalnum = res.detail.totalRow;
        } else {
          this.$message.error(res.description);
        }
      })
    },
    //切换页面条数
    flowSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.flowFenye.pagesize = val;
      this.flowCurrentPage = 1;
      this.getFlows();
    },
    //切换分页
    flowCurrentChange(val) {
      this.flowCurrentPage = val;
      this.getFlows();
    },
    //获取黑名单客户的详情
    guestdetail(row){
      this.accountUuid = row.accountuuid
      this.formDialog = true
    }
		  
  }
}
</script>

<style lang="scss" scoped>
  .paginationbox{
    text-align: right;
    margin-top: 20px;
  }
  .showdatanumber{
    margin: 0 10px 10px;
    color: #409EFF;
    span{
      margin: 0 5px;
      font-size: 20px;
    }
  }
  .searchbox{
    margin: 0 10px;
  }
  .formbox{
    width: 94%;
  }
</style>